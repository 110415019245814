import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

/**
 * Determines if the current user is authenticated with an IdP. Likely in the first-time user process.
 *   * Signed in with an IdP
 * @returns {boolean | null} isAuthenticated - Whether the user is authenticated with an IdP.
 */
const useIsUserAuthenticatedWithIdp = (): boolean | null => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkIsUserAuthenticated = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        if (error === 'The user is not authenticated') {
          setIsAuthenticated(false);
        } else {
          console.error(
            'Error checking if user is authenticated with IdP',
            error
          );
        }
      }
    };

    checkIsUserAuthenticated();
  }, []);

  return isAuthenticated;
};

export default useIsUserAuthenticatedWithIdp;
