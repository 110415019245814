import React, { useState } from 'react';

import Button from 'components/Buttons/Button';
import { useSelector } from 'react-redux';
import { currentUserLookupIdSelector } from 'redux/selectors';
import { Form, Formik } from 'formik';
import { Select } from 'components/Form/Fields';
import { SubmitButton } from 'components/Form/Buttons';
import oWnApi from 'utils/WnApi';

const TaxStatementPrototype = () => {
  const [taxStatementPdfUrl, setTaxStatementPdfUrl] = useState('');
  const [uiPhase, setUiPhase] = useState('form'); // form, loading, success, error
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);

  const reset = () => {
    setUiPhase('form');
    setTaxStatementPdfUrl('');
  };

  const handleDownload = async (formValues: any) => {
    try {
      const path = `constituents/${sCurrentUserLookupId}/reports/tax_statement?targetLookupId=8-10073397&year=${formValues.year}`; // Wes Waters for testing. Should be: sCurrentUserLookupId,
      const response: any = await oWnApi.get(path);
      console.log(response);
      if (response.status !== 200) {
        throw new Error(
          `Error downloading PDF: ${response.status}: ${response.statusText}`
        );
      }

      // Create a download link and trigger the download
      // const link = document.createElement('a');
      // link.href = response.data.pdfUrl;
      // link.target = '_blank';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setTaxStatementPdfUrl(response.data.pdfUrl);
      setUiPhase('success');
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setUiPhase('error');
    }
  };
  return (
    <div>
      <h3>Athletic Tax Statements Prototype</h3>
      {uiPhase === 'form' && (
        <Formik
          onSubmit={handleDownload}
          initialValues={{
            year: '2023',
          }}
        >
          <Form>
            <Select
              label='Year'
              id='tax-year'
              name='year'
              valueKey='value'
              options={[
                {
                  label: '2023',
                  value: '2023',
                },
                {
                  label: '2022',
                  value: '2022',
                },
              ]}
            />
            <SubmitButton>Get PDF</SubmitButton>
          </Form>
        </Formik>
      )}

      {uiPhase === 'success' && (
        <div>
          <a
            href={taxStatementPdfUrl}
            target='_blank'
            rel='noreferrer'
            download
          >
            View 2023 tax statement
          </a>
          <br />
          <a
            href='https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:4707ed49-4e5b-48f6-a320-1c53eb2126b9'
            target='_blank'
            rel='noreferrer'
            style={{
              display: 'block',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            View 2023 tax benefit chart
          </a>
        </div>
      )}

      {['success', 'error'].includes(uiPhase) && (
        <Button fnHandleClick={reset} sFlavor='secondary'>
          Back
        </Button>
      )}
    </div>
  );
};

export default TaxStatementPrototype;
