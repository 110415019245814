import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import qs from 'qs';

import LoadingZone from 'components/LoadingZone';
import oWnApi from 'utils/WnApi';
import { triggerGoogleTagManagerEvent } from 'utils/common';
import usePageTitle from 'utils/hooks/usePageTitle';
import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';
import GoogleReCaptcha from 'components/Form/Fields/GoogleReCaptcha';
import useIsUserAuthenticatedWithIdp from 'utils/hooks/useIsUserAuthenticatedWithIdp';
import AuthLayout from '../../AuthLayout';
import WfuIdField from './WfuIdField';
import SsnLastFourField from './SsnLastFourField';
import { lastFourSsnSchema, bannerIdSchema } from './schemas';
import ExpiredMagicLink from './ExpiredMagicLink';
import ValidationFail from './ValidationFail';
import validationLockLogo from '../../images/validation-lock.svg';
import magicLetter from '../../images/hogwarts-letter.svg';
import magicLetterSent from '../../images/hogwarts-letter-closed-with-sparkles.svg';
import OtherValidationOptions from './OtherValidationOptions';
import MagicLink from '../MagicLink';

const oInitialValues = {
  ssn1: '',
  ssn2: '',
  ssn3: '',
  ssn4: '',
  bannerid: '',
  reCaptchaToken: window.Cypress ? 'cypress' : '',
};

const sFormId = 'match-validation-form';

const MatchValidation = () => {
  usePageTitle('Sign Up');
  const oFormRef = useRef();
  const oReCaptchaFieldRef = useRef();
  const fnNavigate = useNavigate();
  const oParams = useParams();
  const oLocation = useLocation();
  const oConstituent = oLocation.state?.oConstituent || null;
  const sMatchType = oParams.type;
  const bHasSsn = oConstituent?.HASLASTFOUR === '1';
  const bHasWfuId = oConstituent?.HASBANNERID === '1';
  const bHasTempToken = oConstituent?.HASTEMPTOKEN === '1';
  const bIsMagicLinkEligible = oConstituent?.MAGICLINKELIGIBLE === '1';
  const [isLoading, setIsLoading] = useState(false);
  const [sVerificationResult, fnSetVerificationResult] = useState('');
  const [bIsContinueDisabled, fnSetIsContinueDisabled] = useState(true);
  const [bIsMagicLinkSent, fnSetIsMagicLinkSent] = useState(false);
  const [sSelectedValidationType, fnSetSelectedValidationType] = useState(
    bHasSsn ? 'ssn' : 'bannerid'
  );
  const bIsAuthenticated = useIsUserAuthenticatedWithIdp();

  // Users might not be authenticated if they used a magic link. Redirect them to sign in.
  // Ex: User requested a magic link in Chrome. They click the link in their email in Firefox.
  useEffect(() => {
    if (bIsAuthenticated === false) {
      const { pathname, search } = oLocation;
      // Save the current URL, so the user can come back after signing in.
      // Ex: /sign-up/validation/magic-link?lookupid=8-xxxxxxx&magic=tada
      const sRedirectUrl = `${pathname}${search}`;
      const searchParams = new URLSearchParams({
        redirect: sRedirectUrl,
      });
      const signInUrl = `/sign-in?${searchParams.toString()}`;
      console.log('Redirecting to:', signInUrl);
      fnNavigate(signInUrl);
    }
  }, [bIsAuthenticated, fnNavigate, oLocation, sMatchType]);

  useEffect(() => {
    const fnHandleMagicLink = async () => {
      setIsLoading(true);
      const oQueryParams = qs.parse(
        qs.parse(oLocation.search, { ignoreQueryPrefix: true })
      );
      const oPostData = {
        bannerid: oQueryParams.magic,
        lastfour: '',
        lookupid: oQueryParams.lookupid,
        type: sMatchType,
      };
      const oResponse = await oWnApi.post(
        `users/${oQueryParams.lookupid}`,
        oPostData
      );
      if (oResponse.status === 200) {
        const sResult = oResponse.data.DATA.VERIFICATIONRESULT;
        fnSetVerificationResult(sResult);
        triggerGoogleTagManagerEvent(
          'MatchValidation',
          `magic link validation attempted: ${sResult}`
        );
      }
      setIsLoading(false);
    };

    if (sMatchType === 'magic-link' && bIsAuthenticated) {
      fnHandleMagicLink();
    }
  }, [sMatchType, oLocation, bIsAuthenticated]);

  useEffect(() => {
    if (sVerificationResult === 'SUCCESS') {
      fnNavigate('/sign-up/finalize-account');
    }
  }, [sVerificationResult, fnNavigate]);
  // Ask the user to contact us if we don't have any data to verify their identity

  if (
    !bHasSsn &&
    !bHasWfuId &&
    !bHasTempToken &&
    !bIsMagicLinkEligible &&
    sMatchType !== 'magic-link'
  ) {
    fnNavigate('/sign-up/help/noverify');
  }

  const fnGetInputComponentByValidationType = () => {
    switch (sSelectedValidationType) {
      case 'ssn':
        return <SsnLastFourField matchType={sMatchType} />;
      case 'bannerid':
      case 'deacontoken':
        return (
          <WfuIdField
            matchType={sMatchType}
            sSelectedValidationType={sSelectedValidationType}
          />
        );
      case 'magic-link':
        return (
          <MagicLink
            bIsMagicLinkSent={bIsMagicLinkSent}
            fnSetIsMagicLinkSent={fnSetIsMagicLinkSent}
          />
        );
      default:
        return <SsnLastFourField matchType={sMatchType} />;
    }
  };

  useEffect(() => {
    if (sMatchType !== 'magic-link') {
      oFormRef.current.resetForm();
      fnSetVerificationResult(null);
    }
  }, [sSelectedValidationType, sMatchType]);

  const fnHandleContinue = async (oFormValues, oFormikBag) => {
    setIsLoading(true);
    const sSanitizedBannerId = oFormValues.bannerid
      ? oFormValues.bannerid.trim()
      : '';
    const sLastFour = `${oFormValues.ssn1}${oFormValues.ssn2}${oFormValues.ssn3}${oFormValues.ssn4}`;
    const oPostData = {
      bannerid: sSanitizedBannerId,
      lastfour: sLastFour,
      lookupid: oConstituent?.LOOKUPID,
      type: sMatchType,
      reCaptchaToken: oFormValues.reCaptchaToken,
    };
    const oResponse = await oWnApi.post(
      `users/${oConstituent?.LOOKUPID}`,
      oPostData
    );
    if (oResponse.status === 200) {
      const sResult = oResponse.data.DATA.VERIFICATIONRESULT;
      fnSetVerificationResult(sResult);
      let sValidationType = 'ssn';
      if (sSanitizedBannerId) {
        sValidationType =
          sSanitizedBannerId.length === 6 ? 'deacon token' : 'banner ID';
      }
      triggerGoogleTagManagerEvent(
        'MatchValidation',
        `${sValidationType} validation attempted: ${sResult}`
      );
    } else if (oResponse.data.includes('reCAPTCHA')) {
      oFormikBag.setFieldValue('reCaptchaToken', '');
      oReCaptchaFieldRef.current.reset();
    }
    setIsLoading(false);
  };

  // fnHandleSubmitDisable handles disabling the continue button when the form is invalid or dirty,
  // only when changed from the initial values.
  const fnHandleSubmitDisable = (formikProps) => {
    fnSetIsContinueDisabled(!(formikProps?.isValid && formikProps?.dirty));
  };

  return (
    <AuthLayout
      sMatchType={sMatchType}
      continueButton={
        [sSelectedValidationType, sMatchType].includes('magic-link') ? null : (
          <ContinueButton
            bContinueButtonDisabled={bIsContinueDisabled}
            sFormId={sFormId}
            sType='submit'
          />
        )
      }
      left={
        sMatchType === 'magic-link' ? (
          <LoadingZone isLoading={isLoading}>
            {['FAIL', 'EXCEEDED'].includes(sVerificationResult) && (
              <ExpiredMagicLink sVerificationResult={sVerificationResult} />
            )}
          </LoadingZone>
        ) : (
          <div className='validation__formContainer'>
            <Formik
              innerRef={oFormRef}
              onSubmit={fnHandleContinue}
              initialValues={oInitialValues}
              validationSchema={
                sSelectedValidationType === 'ssn'
                  ? lastFourSsnSchema
                  : bannerIdSchema
              }
              validateOnBlur
            >
              {(formikProps) => (
                <Form
                  onChange={fnHandleSubmitDisable(formikProps)}
                  id={sFormId}
                >
                  {fnGetInputComponentByValidationType()}
                  {sSelectedValidationType !== 'magic-link' && (
                    <GoogleReCaptcha oReCaptchaRef={oReCaptchaFieldRef} />
                  )}
                  <LoadingZone isLoading={isLoading}>
                    {['FAIL', 'EXCEEDED'].includes(sVerificationResult) && (
                      <ValidationFail
                        sVerificationResult={sVerificationResult}
                      />
                    )}
                  </LoadingZone>
                  {sVerificationResult !== 'EXCEEDED' && !bIsMagicLinkSent && (
                    <OtherValidationOptions
                      sSelectedValidationType={sSelectedValidationType}
                      oConstituent={oConstituent}
                      sMatchType={sMatchType}
                      fnHandleClick={fnSetSelectedValidationType}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )
      }
      right={
        <div className='matching__searchingRightContainer'>
          {sSelectedValidationType === 'magic-link' ? (
            <img
              src={bIsMagicLinkSent ? magicLetterSent : magicLetter}
              className={
                bIsMagicLinkSent
                  ? 'magicLink__magicLetterSent'
                  : 'magicLink__magicLetter'
              }
              alt='a magical letter'
            />
          ) : (
            <img
              src={validationLockLogo}
              className='validation__lockLogo'
              alt='a big purple lock'
            />
          )}
        </div>
      }
    />
  );
};

export default MatchValidation;
